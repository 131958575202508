import Link from 'next/link'
import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from "classnames"

import styles from "../styles/social-icon.module.css"

export const DEFAULT_SIZE = 24;

export const SocialIcon = ({ target, name, icon, size, iconStyle, children }) => {

    const isArray = icon instanceof Array

    return (
        <Link href={target} passHref>
            <a className={classNames("group h-min w-min relative mx-1 flex flex-row", styles.group)} target="_blank" rel="noreferrer" title={name}>
                <div className={classNames("w-6 h-6 mx-1", isArray ? iconStyle : null)}>
                    {isArray
                        ? <FontAwesomeIcon className={`text-2xl ${iconStyle || ""}`} icon={icon} />
                        : <Image src={icon} width={size ?? DEFAULT_SIZE} height={size ?? DEFAULT_SIZE} className={`rounded-full absolute ${iconStyle || ""}`} alt={props.name} />}
                </div>
                {children}
            </a>
        </Link>
    )
}