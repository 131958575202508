import TextDE from "./de/main-text"
import TextEN from "./en/main-text"
import { SocialIcon } from "./social"
import classNames from "classnames"
import socialStyles from "../styles/social-icon.module.css"


export const Hero = ({ lang }) => {

    let instaText = "Follow us on Instagram"
    let HeroText = TextEN
    switch (lang) {
        case 'de': {
            HeroText = TextDE
            instaText = "Folge uns auf Instagram"
            break
        }
        case 'en': {
            HeroText = TextEN
            instaText = "Follow us on Instagram"
            break
        }
    }

    return (
        <div className="lg:w-2/3 lg:float-right xl:pr-24 xl:pl-8">
        <div className="m-auto relative bg-center w-11/12 flex items-center justify-center">
            <div className="text-center bg-gray-900 bg-opacity-50 backdrop-blur-md p-2 rounded-lg text-gray-200 h-4/6 flex flex-col">
                {/* <h1 className="text-3xl tracking-wide font-bold max-w">Cook-A-Geddon</h1> */}
                <HeroText className="self-center" />
                <div className="flex justify-center items-end bottom-2 h-full">
                    <SocialIcon name={instaText} target="https://instagram.cook-a-geddon.de" icon={['fab', 'instagram']} iconStyle="group-hover:text-yellow-200">
                        <p className={classNames("font-bold", socialStyles.instagram)}>cook_a_geddon</p>
                    </SocialIcon>
                    </div>
                </div>
            </div>
        </div>
    )
}
