export default () => (
  <div className="text-justify m-2">
    <p>
      Cook-A-Geddon ist ein Action-Adventure, das in einer Fantasywelt spielt, in der
      sich alles rund um Essen dreht. Der Spieler steuert 3 Köche, die sich durch das
      Land Delikatien kämpfen müssen, um zum bösen Schmalztitan zu gelangen und
      diesen zu besiegen. Bewaffnet mit Baguette-Dolchen, einem vollautomatischen
      Wurstwerfer oder einer Speerghetti machen sich die Helden auf ihren
      Rachefeldzug. Unterstützt werden sie von der Tortilla-Guerilla unter Führung von
      Chio Guevara und Giorgio Parmigiano.
    </p>
    <p className="my-2">
      Das Spiel konzentriert sich auf schnelle, actionreiche Kämpfe, flüssigen Wechsel
      zwischen den 3 Helden, um ihre Fähigkeiten optimal einzusetzen, schrulligen
      Charakteren und einer Fülle an Wortspielen.
    </p>
  </div>
);
