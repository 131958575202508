import Head from 'next/head'
import { Hero } from '../components/hero'
import Logo from '../components/logo'

export default () => {

  const lang = "de"

  return (
    <>
      <Head>
        <title>Cook A Geddon | Home</title>
      </Head>
      <div >
        <div className="py-8">
          <Logo />
        </div>
        <Hero lang={lang} />
      </div>
    </>
  )
}
