export default () => (
  <div className="text-justify m-2">
    <p>
      Cook-A-Geddon is a quirky action-adventure set in a stylized and food-themed
      fantasy world. The player controls three chefs who are on a path of vengeance
      against the mighty grease titan who burned down their restaurants and subdued
      their homeland Delicacia. With plenty of skills and the help of the infamous tortilla-
      guerilla led by Chio Guevara they aim to set up the resistance and unite their allies
      for the ultimate showdown against the grease titan and his minions.
    </p>
    <p className="my-2">
      The game is
      focused on fast-paced action combat, fluently switching between the 3 heroes to
      optimally utilize their different abilities, odd characters and plenty of puns.
    </p>
  </div>
);
